import { Redirect } from "react-router-dom";
import { AUTH_URL } from "./SignIn";

const SignOut = () => {
  fetch(AUTH_URL + '/user/logout', { credentials: 'include' })
  sessionStorage.setItem('token', '');
  return <Redirect to="/" />
};

export default SignOut;
