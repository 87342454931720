import React, {useState} from "react";
import {Redirect} from "react-router-dom";

export const AUTH_URL = "https://mibival.quodata.de"

const SignIn = () => {
    const [showPwd, setShowPwd] = useState(false);
    const [username, setUsername] = useState('');
    const [pwd, setPwd] = useState('');
    const [token, setToken] = useState(sessionStorage.getItem('token') || '')
    const [error, setError] = useState('')

    function onSubmit(event) {
        fetch(AUTH_URL + "/user/login", {
            credentials: 'include',
            method: 'POST',
            body: new URLSearchParams({name: username, pass: pwd, form_id: 'user_login_form', op: 'Log In'})
        }).then(
            resp => {
                if (!resp.ok) {
                    setError('Beim Login ist ein Fehler aufgetreten. Bitte kontraktieren Sie einen Administrator, falls das Problem weiterhin besteht.')
                } else fetch(AUTH_URL + "/rest/token", {credentials: 'include'}).then(
                    resp => {
                        if (!resp.ok) {
                            setError('Ungültige Zugangsdaten')
                        } else resp.json().then(
                            data => {
                                sessionStorage.setItem('token', data['token'])
                                setToken(data['token'])
                            })
                    })
            }
        )
        event.preventDefault()
    }

    if (token) {
        return <Redirect to="/internal"/>
    }
    return (
        <section className="authentication-form"
                 style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/auth-bg.jpg)`}}>
            <div>
                <h2 className="title text-center">
                    <span>Login</span>
                </h2>
                <p className="text-center">
                    Welcome to login area for HERMES project partners. Please login with your email address and
                    password.
                </p>
                <div className="card">
                    {error && <div className="error-wrapper">Ungültige Zugangsdaten!</div>}
                    <form className="theme-form" onSubmit={onSubmit}>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email address"
                                name="email"
                                required="required"
                                onChange={(event) => {
                                    setUsername(event.target.value)
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                required=""
                                name="login[password]"
                                type={showPwd ? "text" : "password"}
                                className="form-control"
                                placeholder="Password"
                                onChange={(event) => {
                                    setPwd(event.target.value)
                                }}
                            />
                            <div
                                className="show-hide"
                                onClick={() => {
                                    setShowPwd(!showPwd);
                                }}
                            >
                                <span className={!showPwd ? "show" : ""}></span>
                            </div>
                        </div>
                        <div className="form-button text-center">
                            <button
                                type="submit"
                                className="btn btn-custom btn-lg theme-color"
                            >
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default SignIn;
