import React from "react";
import {Route, Switch} from "react-router-dom";
import "./App.css";
import Internal from "./Internal";
import LandingPage from "./LandingPage";
import PageNotFound from "./Pages/404";
import SignIn from "./Pages/SignIn";
import SignOut from "./Pages/SignOut";

function App() {
    localStorage.setItem("color", "color-3");
    return (
        <div className="App">
            <Switch>
                <Route exact path={`${process.env.PUBLIC_URL}/`} component={LandingPage}/>
                <Route exact path={`${process.env.PUBLIC_URL}/internal`} component={Internal}/>
                <Route path={`${process.env.PUBLIC_URL}/sign-in`} component={SignIn}/>
                <Route path={`${process.env.PUBLIC_URL}/sign-out`} component={SignOut}/>
                <Route component={PageNotFound}/>
            </Switch>

        </div>
    );
}

export default App;
