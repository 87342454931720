import React from "react";

const Footer = () => {
    return (
        <div>
            <section className="p-0">
                <div className="container-fluid">
                    <div className="bottom-section">
                        <div className="row" style={{ display: 'block' }}>
                            <div className="col-md-6 p-0" style={{maxWidth: "100%"}}>
                                <div className="address-bar">
                                    <div style={{ marginLeft: '4em' }}>
                                        <ul className="footer-style">
                                            <li>
                                                <div className="footer-icon">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/locate.png`}
                                                         alt="locate"/>
                                                </div>
                                                <div className="footer-address">
                                                    <a href="javascript" onClick={(e) => {
                                                        e.preventDefault()
                                                    }}>
                                                        QuoData GmbH, Prellerstraße 14, D-01309 Dresden
                                                    </a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-icon">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/telephone.png`}
                                                         alt="telephone"/>
                                                </div>
                                                <div className="footer-address">
                                                    <a href="javascript" onClick={(e) => {
                                                        e.preventDefault()
                                                    }}>+49 351 - 4028867 0</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-icon">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/fotter-email.png`}
                                                        alt="fotter-email"/>
                                                </div>
                                                <div className="footer-address">
                                                    <a href="mailto:info@quodata.de">info@quodata.de</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-icon">
                                                    <img width="27"
                                                         src={`${process.env.PUBLIC_URL}/assets/images/web.png`}
                                                         alt=""/>
                                                </div>
                                                <div className="footer-address">
                                                    <a href="https://www.quodata.de">https://www.quodata.de</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-address">
                                                    <a>HiPSC video courtesy of AG Guan, TU Dresden</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="copyright-section index-footer">
                <p></p>
            </div>

            <div className="tap-top">
                <div>
                    <i className="fa fa-angle-double-up"></i>
                </div>
            </div>
        </div>
    );
};

export default Footer;
