import React from "react";
import translator from "../translator";

const About = ({lang}) => {

    const bgImg = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/about-bg.png`
    };

    const t = translator(lang)
    return (
        <section id="about" className="about" style={bgImg}>
            <div className="container">
                <div className="row ">
                    <div className="col-md-7">
                        <div className="about-contain">
                            <div>
                                <h2 className="title">
                                    {t('Über')} <span>HERMES</span>
                                </h2>
                                <p className="caption-about">
                                    {t('Hinter dem Projekt HERMES stehen zwei sächsische Forschungseinrichtungen und zwei sächsische KMUs. Das Akronym steht für "Highspeed Erfassung und Regelung Mikrophysiologischer Eigenschaften mit einer intelligenten Steuerung". Diesem Vorhaben geht der Forschungsverbund aus der QuoData GmbH, dem Fraunhofer-Institut für Werkstoff- und Strahltechnik IWS, der Medizinischen Fakultät der TU Dresden (Institut für Pharmakologie und Toxikologie) und der DELTEC GmbH ab Sommer 2019 für zweieinhalb Jahre gemeinsam nach.')}
                                </p>
                                <p className="caption-about">
                                    {t('Das Ziel des HERMES-Projektes ist die Entwicklung eine innovativen Organ-on-Chip-Systems zur Bestimmung der Vitalität von Herzmuskelzellen. Diagnose und Therapie kardiovaskulärer Krankheiten sind wichtige Aufgabe im Gesundheitsbereich. Laut WHO sind Herz-Kreislauf-Erkrankungen für ca. 50% der frühzeitigen Todesfälle verantwortlich.')}
                                </p>
                                <p className="caption-about">
                                    {t('Im Rahmen der individualisierten Medizin können Wirkstofftestungen an patientenspezifischen Zellen oder Geweben erfolgt. Herzmuskelzellen sind hierbei besonders interessant, da diese auch in-vitro typische Kontraktions- und Relaxationsbewegungen ausführen. So kann beispielsweise die Testung von Medikamenten im Hinblick auf gewünschte Effekte oder auch Nebenwirkungen erfolgen.')}
                                </p>
                                <p className="caption-about">
                                    {t('Um die Bewegungsmuster zu erfassen und auszuwerten, wird ein automatisiertes Messsystems zur Bestimmung der Vitalität von Herzmuskelzellen benötigt. Solch ein cyber-physisches System wird im HERMES-Projekt entwickelt werden. Mit dessen Daten, zusätzlichen Markern und Expertenwissen können Algorithmen (beziehungsweise neuronale Netze) zur automatisierten Bewertung eingesetzt werden.')}
                                </p>
                                <p className="caption-about">
                                    {t('Um die Bewegungsmuster von Herzmuskelzellen optisch zu erfassen und zu bewerten, wird ein automatisiertes Messsystems zur Bestimmung der Vitalität von Herzmuskelzellen im HERMES-Projekt entwickelt. Dieses cyber-physische System besteht aus folgenden Modulen:')}
                                </p>
                                <ul className='with_dot'>
                                    <p className="caption-about">
                                        <li>
                                            <b>{t('Mikrophysiologische Systeme und Protokolle für das Kultivieren von Herzmuskelzellen unter definierten Bedingungen')}</b>
                                        </li>
                                        <li>
                                            <b>{t('Herzmuskelzell-Aktivitätsmesssystem mit integrierten Modulen zum Steuern und Regeln von mikrophysiologischen Systemen')}</b>
                                        </li>
                                        <li>
                                            <b>{t('Algorithmen und Software für die Bewegungsanalyse und Datenanalyse-Cloud')}</b>
                                        </li>
                                    </p>
                                </ul>
                                <h3>{t('Die Herausforderung')}</h3>
                                <p className="caption-about">
                                    {t('QuoData wird sich neben der Projektleitung hauptsächlich mit der Analyse der Bilddaten, der Entwicklung und Anwendung von Maschine Learning-Algorithmen zur Auswertung der Datensätze, der Validierung der neuen Auswertemethoden sowie der Entwicklung eines Cloud-basierten Systems mit Nutzer-Interaktion beschäftigen.')}
                                </p>
                                <p className="caption-about">
                                    {t('Eine Herausforderung bei der Durchführung des Projektes besteht nicht nur in den großen zu verarbeitenden Datenmengen, sondern auch in der Problematik, statistisch signifikante Erkenntnisse aus den gewonnenen Daten abzuleiten, beispielsweise in Bezug auf die Wirksamkeit verabreichter Medikamente. Ende Juni 2019 fand das Auftakttreffen für HERMES statt, welches mit Mitteln aus dem Europäischen Fond für Regionale Entwicklung (EFRE-Technologieförderung) gefördert wird.')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="about-decor">
                            <div className="about-circle2">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/grapes/grape1.png`} alt="banner1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
