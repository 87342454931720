const translations = {
    'Über': 'About',
    'Hinter dem Projekt HERMES stehen zwei sächsische Forschungseinrichtungen und zwei sächsische KMUs. Das Akronym steht für "Highspeed Erfassung und Regelung Mikrophysiologischer Eigenschaften mit einer intelligenten Steuerung". Diesem Vorhaben geht der Forschungsverbund aus der QuoData GmbH, dem Fraunhofer-Institut für Werkstoff- und Strahltechnik IWS, der Medizinischen Fakultät der TU Dresden (Institut für Pharmakologie und Toxikologie) und der DELTEC GmbH ab Sommer 2019 für zweieinhalb Jahre gemeinsam nach.':
        'QuoData is thrilled to kickstart a new project called HERMES (Highspeed Erfassung und Regelung Mikrophysiologischer Eigenschaften mit einer intelligenten Steuerung), along with the Fraunhofer Institute for Material and Beam Technology (IWS), the Medical Faculty of TU Dresden (Institute for Pharmacology and Toxicology) and DELTEC GmbH. Since June 2019, the Saxony based research institutions and SMEs are working together on developing organ-on-chip technologies for improved drug testing and drug safety.',
    'Das Ziel des HERMES-Projektes ist die Entwicklung eine innovativen Organ-on-Chip-Systems zur Bestimmung der Vitalität von Herzmuskelzellen. Diagnose und Therapie kardiovaskulärer Krankheiten sind wichtige Aufgabe im Gesundheitsbereich. Laut WHO sind Herz-Kreislauf-Erkrankungen für ca. 50% der frühzeitigen Todesfälle verantwortlich.':
        'The project aims to design and develop an integrated, next generation heart-on-chip platform. Such a tunable platform, on which cardiac stem cells can be cultured can be used directly to evaluate drug performance. Considering that cardiovascular diseases are responsible for about 50% of premature deaths according to the WHO, engineering platforms to facilitate development of therapeutics is essential. Moreover, such a platform will promote individualized medicine, in which active substances are tested on patient-specific cells or tissues. Within the scope of the project, the efficacy of the tested substances will be evaluated by studying the response of stem cells in real time using an automated measuring system.',
    'Im Rahmen der individualisierten Medizin können Wirkstofftestungen an patientenspezifischen Zellen oder Geweben erfolgt. Herzmuskelzellen sind hierbei besonders interessant, da diese auch in-vitro typische Kontraktions- und Relaxationsbewegungen ausführen. So kann beispielsweise die Testung von Medikamenten im Hinblick auf gewünschte Effekte oder auch Nebenwirkungen erfolgen.':
        ' ',
    'Um die Bewegungsmuster zu erfassen und auszuwerten, wird ein automatisiertes Messsystems zur Bestimmung der Vitalität von Herzmuskelzellen benötigt. Solch ein cyber-physisches System wird im HERMES-Projekt entwickelt werden. Mit dessen Daten, zusätzlichen Markern und Expertenwissen können Algorithmen (beziehungsweise neuronale Netze) zur automatisierten Bewertung eingesetzt werden.':
        'The project aims to design and develop an integrated, next generation heart-on-chip platforms. Such a tunable platform, on which cardiac stem cells can be cultured can be used directly to evaluate drug performance, and thus reduce reliance on animal models. Considering that cardiovascular diseases are responsible for about 50% of premature deaths according to the WHO, engineering platforms to facilitate development of therapeutics is essential. Moreover, such a platform will promote individualized medicine, in which active substances are tested on patient-specific cells or tissues. Within the scope of the project, the efficacy of the tested substances will be evaluated by studying the response of stem cells in real time using an automated measuring system.',
    'Um die Bewegungsmuster von Herzmuskelzellen optisch zu erfassen und zu bewerten, wird ein automatisiertes Messsystems zur Bestimmung der Vitalität von Herzmuskelzellen im HERMES-Projekt entwickelt. Dieses cyber-physische System besteht aus folgenden Modulen:':
        'Such a system will primarily consist of:',
    'Mikrophysiologische Systeme und Protokolle für das Kultivieren von Herzmuskelzellen unter definierten Bedingungen':
        'Microphysiological systems and protocols for the cultivation of heart muscle cells under defined conditions',
    'Herzmuskelzell-Aktivitätsmesssystem mit integrierten Modulen zum Steuern und Regeln von mikrophysiologischen Systemen':
        'Heart muscle cell activity measurement system with integrated modules for controlling and regulating microphysiological systems',
    'Algorithmen und Software für die Bewegungsanalyse und Datenanalyse-Cloud':
        'Algorithms for motion analyses and cloud based data analysis',
    'Die Herausforderung': ' ',
    'QuoData wird sich neben der Projektleitung hauptsächlich mit der Analyse der Bilddaten, der Entwicklung und Anwendung von Maschine Learning-Algorithmen zur Auswertung der Datensätze, der Validierung der neuen Auswertemethoden sowie der Entwicklung eines Cloud-basierten Systems mit Nutzer-Interaktion beschäftigen.':
        'In addition to leading and managing the project, QuoData will be mainly responsible for the bioimage processing, developing machine learning algorithms, validating new evaluation methods and developing a cloud-based system with user interface.',
    'Eine Herausforderung bei der Durchführung des Projektes besteht nicht nur in den großen zu verarbeitenden Datenmengen, sondern auch in der Problematik, statistisch signifikante Erkenntnisse aus den gewonnenen Daten abzuleiten, beispielsweise in Bezug auf die Wirksamkeit verabreichter Medikamente. Ende Juni 2019 fand das Auftakttreffen für HERMES statt, welches mit Mitteln aus dem Europäischen Fond für Regionale Entwicklung (EFRE-Technologieförderung) gefördert wird.':
        ' ',
    'Fraunhofer-Institut für Werkstoff- und Strahltechnik IWS':
        'Fraunhofer Institute for Material and Beam Technology IWS',
    'Medizinische Fakultät der TU Dresden (Institut für Pharmakologie und Toxikologie)':
        'Medical Faculty of TU Dresden (Institute for Pharmacology and Toxicology)'
}

function clean(text) {
    return text.replace('\n', '').replace(/\s\s+/g, ' ')
}

export default function translator(lang) {
    return (text) => lang === 'en' ? (translations[clean(text)] || text) : text
}
