import React, {useState} from "react";
import "react-modal-video/scss/modal-video.scss";
import Tilt from "react-tilt";
import About from "./components/About";
import Footer from "./components/Footer";
import Menu from "./components/Navbar";
import Partners from "./components/Partners";
import translator from "./translator";

const LandingPage = () => {

    const bgImg = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/Homev3.png)`
    }

    document.body.classList.remove("landing-page");
    document.body.classList.remove("home-style-two");
    document.body.classList.add("home-style");
    document.body.classList.add("three");

    const [lang, setLang] = useState(sessionStorage.getItem('lang') || 'en')

    function onNewLang(new_lang) {
        sessionStorage.setItem('lang', new_lang)
        setLang(new_lang)
    }

    const t = translator(lang)

    return (
        <div>
            <Menu onLangChange={onNewLang} lang={lang}/>
            <section id="home" className="home home-three vertical-scrolling" style={bgImg}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-sm-12">
                            <div className="home-contain">
                                <div className="text-white">
                                    <div className="contain">
                                        <h1 className="text-white">
                                            HERMES
                                        </h1>
                                        <p className="slide-cap-desc">
                                            {t('Highspeed Erfassung und Regelung Mikrophysiologischer Eigenschaften mit einer intelligenten Steuerung')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="home-right">
                                <Tilt
                                    options={{perspective: 110, speed: 400, max: 1.2, scale: 1}}
                                >
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/cell_gif.gif`}
                                        className="img-fluid"
                                        alt="grape_animation"
                                    />
                                </Tilt>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* About Component*/}
            <About lang={lang}/>

            {/*Feature Component*/}
            <Partners lang={lang}/>

            <section style={{paddingTop: 0, paddingBottom: 0}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="footer-text" style={{padding: '30px'}}>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/partners/funding_${lang}.jpg`}
                                     className="img-fluid" alt="funding"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Blog Component could be used for short results presentation*/}
            {/* <Blog /> */}

            {/* <KnowMore lang={lang} /> */}

            {/*Footer Component*/}
            <Footer/>
        </div>
    );
};

export default LandingPage;
