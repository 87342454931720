import React from "react";
import translator from "../translator";

const Partners = ({lang}) => {
    const bgImg = {backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/feature-bg1.png)`}

    const t = translator(lang)
    return (
        <section id="feature" className="feature" style={bgImg}>
            <div className="feature-decor">
                <div className="feature-circle1">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/grapes/grape1.png`} alt=""/>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="offset-lg-4 col-lg-8">
                        <div className="row">
                            <div className="col-sm-12 mrgn-md-top">
                                <h2 className="title">
                                    {t('Projektpartner')}
                                </h2>
                            </div>
                            <div className="col-12 col-md-6">
                                <ul className="feature-style">
                                    <li>
                                        <div className="feature-icon">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/partners/fraunhofer_ivv.png`}
                                                alt="icon"/>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>&nbsp;</h3>
                                        </div>
                                        <div>
                                            <p>
                                                {t('Fraunhofer-Institut für Werkstoff- und Strahltechnik IWS')}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="feature-icon">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/partners/tud-logo.png`}
                                                 alt="icon"/>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>&nbsp;</h3>
                                        </div>
                                        <div>
                                            <p>
                                                {t('Medizinische Fakultät der TU Dresden (Institut für Pharmakologie und Toxikologie)')}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6 sm-m-top">
                                <ul className="feature-style">
                                    <li>
                                        <div className="feature-icon">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/partners/deltec-logo.png`}
                                                alt="icon"/>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>&nbsp;</h3>
                                        </div>
                                        <div>
                                            <p>
                                                {t('DELTEC GmbH')}<br/><br/>
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="feature-icon">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/partners/qd.png`}
                                                 alt="icon"/>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>&nbsp;</h3>
                                        </div>
                                        <div>
                                            <p>
                                                {t('QuoData Gesellschaft für Qualitätsmanagement und Statistik mbH')}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partners;
