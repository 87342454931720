import React from "react";
import {Redirect} from "react-router-dom";

const Apps = () => {
    const bgImg = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)`
    };


    // Only authenticated users may visit here
    const token = sessionStorage.getItem('token')
    if (!token) return <Redirect to={'/sign-in'}/>

    // Dynamic Blog Data Easy to Update
    const data = [
        {
            title: "HiPSC Maturity",
            link: "apps/hipsc_maturity?token=" + token,
            photo: `${process.env.PUBLIC_URL}/assets/images/blog/9.jpg`,
        },
        {
            title: "Maia Cloud",
            link: "apps/maia_cloud?token=" + token,
            photo: `${process.env.PUBLIC_URL}/assets/images/blog/9.jpg`,
        },
    ];

    // Dynamic Blog Data Loop
    const DataList = data.map((val, i) => {
        return (
            <div className="blog-item" key={i}>
                <div className="blog-block" style={bgImg}>
                    <div className="blog-box">
                        <div className="overflow-hidden">
                            <a href={val.link} target='_blank' rel="noreferrer">
                                <img src={val.photo} className="img-fluid" alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="blog-text">
                    <a href={val.link} target='_blank' rel="noreferrer">
                        <h3>{val.title}</h3>
                    </a>
                </div>
            </div>
        );
    });

    return (
        <section id="blog" className="blog">
            <div className="about-decor">
                <div className="about-circle2">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/grapes/grape2.png`} className="img-fluid"
                         alt="main-banner1"/>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2 className="title">
                            Alle Apps
                        </h2>
                    </div>
                    <div className="col-sm-12">
                        {DataList}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Apps;
